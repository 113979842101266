<template lang="pug">
.background-wrapper
	.container.section
		h3.intro-title {{ blok.title }}
		.SingaSearchInput.search-input
			.search-right(v-if="inputString" @click.stop.prevent="clearSearch")
				.esc {{ t('search.esc') }}
				SingaIcon(icon="close-outline" size="large")
			SingaField(:label="blok.search_placeholder" labelFor="landerSearch")
				SingaInput#landerSearch(ref="input" :aria-label="t('search.input')" rounded icon="search-outline" :placeholder="blok.search_placeholder" v-model="inputString" @keydown.esc="clearSearch")
					CommonLoadingAnimation(v-if="isLoading")
		h3.songs-text(v-if="!isLoading") {{ songsTitle }}
		h3.songs-text(v-else) {{ blok.searching }}
		SongListItemRowWrap(v-if="songs && songs.length > 0")
			template(v-slot:songs)
				ol.columns.is-multiline.list-container.is-mobile
					SongListItem.column.is-6-mobile-small.is-4-mobile.is-3-tablet(
						:key="result.id"
						v-for="result in songs"
						layout="column"
						:song="result"
					)
					NuxtLink.column.is-6-mobile-small.is-4-mobile.is-3-tablet(:to="localePath('/discover/')" v-if="!isLoading && songs.length < 4")
						ListSeeMore(:isRound="false" :alternativeText="blok.join_cta")
</template>

<script setup lang="ts">
const props = defineProps({
	blok: {
		required: true,
		type: Object
	}
})

const localePath = useLocalePath()
const { $singaApi } = useNuxtApp()

const songlistApiResource = $singaApi.Songs.list
const { loadSongs, results: songs } = useSonglists({ songlistApiResource })
const searchStore = useSearchStore()
const { loadDetailSearch } = searchStore
const { getSongs, isLoading } = storeToRefs(searchStore)

const inputString = ref('')
const timeoutId = ref(-1)
const emptyResults = ref(false)
const { t } = useI18n()
const songsTitle = computed(() => emptyResults.value ? props.blok.no_results_text : props.blok.songs_title)

await loadSongs(false, { page_size: 4, sort: 'top' })

watch(inputString, (value) => {
	if (timeoutId.value > -1) {
		clearTimeout(timeoutId.value)
		timeoutId.value = -1
	}
	if (value) {
		const params = {
			search: inputString.value,
			page_size: 4,
			type: 'songs'
		}
		timeoutId.value = window.setTimeout(async () => {
			await loadDetailSearch(params)
			if (getSongs.value.results.length > 0) {
				songs.value = getSongs.value.results
				emptyResults.value = false
			} else {
				emptyResults.value = true
			}
		}, 1000)
	}
})

const clearSearch = () => {
	inputString.value = ''
	emptyResults.value = false
}
</script>

<style lang="sass" scoped>
.background-wrapper
	background-color: black
	padding-bottom: $spacing-64
	max-width: 900px
	margin: 0 auto

.intro-title
	text-align: center
	color: white
	@include font(basier, bold)
	@include fontSize(xxl)
	max-width: 800px
	padding-top: $spacing-40
	padding-bottom: $spacing-40
	@media (min-width: $tablet)
		padding-top: $spacing-64
		padding-bottom: $spacing-64
		@include fontSize(5xl)

.songs-text
	@include font(basier, regular)
	@include fontSize(m)
	text-align: center
	color: $color-grey-40
	margin-bottom: $spacing-16

.search-input
	margin-bottom: $spacing-64

.SingaSearchInput
	width: 100%
	position: relative
	height: 48px
	:deep(.control .icon)
		width: 32px
		height: 32px
		top: initial
		.base-icon
			width: 32px
			height: 32px
	:deep(input)
		border-color: transparent !important
		text-align: center
		@include font(basier, medium)
		padding-left: 48px !important
		height: 100%
.search-right
	display: flex
	align-items: center
	position: absolute
	right: 12px
	z-index: 1000
	height: 100%
	cursor: pointer
	.icon
		color: $color-grey-50
	.esc
		background-color: $transparent-white-8
		color: $transparent-white-40
		padding: 4px 10px
		border-radius: $radius-default
		margin-right: $spacing-16
		display: none
		@media (min-width: $tablet)
			display: block
.field, .control
	height: 100%

@media (min-width: $tablet)
	.SingaSearchInput:not(.is-small)
		height: 56px
.search-input
	:deep(label)
		text-align: center
		@media (min-width: $mobile-small)
			display: none
	@media (max-width: $mobile-small - 1)
		:deep(#landerSearch::placeholder)
			color: transparent !important
		.search-right
			top: 26px
		:deep(.icon.is-left)
			left: 12px !important
</style>
